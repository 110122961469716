import React from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import * as BsIcons from "react-icons/bs";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

const BeneficiaryInfo = () => {
    const selectedTableUser = null;
    const [beneficiary, setBeneficiary] = useState();
    const [spouse, setSpouse] = useState();

    const { id } = useParams();

    useEffect(() => {
        const fetchBeneficiaryDetails = async () => {
            try {
                const response = await axios.get(
                    `https://api.nha.gov.ph/ep/trx_one_beneficiary/${id}`
                );
                const responseData = response.data;
                setBeneficiary(responseData);
            } catch (error) {
                console.error("Error retrieving data:", error);
            }
        };

        fetchBeneficiaryDetails();
    }, [id]);

    useEffect(() => {
        if (beneficiary) {
            setSpouse(beneficiary.Spouse);
        }
    }, [beneficiary]);
    return (
        <div>
            <Header />
            <Sidebar />

            <div className="content-wrapper pl-2 pr-2 pb-3">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h1>Beneficiary Information</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pl-2 pr-2">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <div
                                    className="card elevation-2"
                                    style={{
                                        borderTop: "4px solid #292726",
                                        borderRadius: "15px",
                                    }}
                                >
                                    <div className="card-header">
                                        <div className="card-title font-weight-bold">
                                            <BsIcons.BsFillPersonCheckFill
                                                className="text-lg mb-1 mr-2"
                                                style={{ color: "#2be0a9" }}
                                            />
                                            Household Head Information
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <ul class="list-group list-group-unbordered mb-3">
                                            <li
                                                class="list-group-item border-top-0"
                                                style={{ lineHeight: "15px" }}
                                            >
                                                <span className="text-muted">
                                                    Household Name <br />
                                                    <small className="text-xs mt-0">
                                                        <i>
                                                            First, Middle, Last
                                                            Name and Suffix
                                                        </i>
                                                    </small>
                                                </span>
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? `${
                                                              beneficiary.firstName
                                                          } ${
                                                              beneficiary.middleName
                                                          } ${
                                                              beneficiary.lastName
                                                          } ${
                                                              beneficiary.suffix ||
                                                              ""
                                                          }`
                                                        : "Loading..."}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    Sex
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? `${beneficiary.sex}`
                                                        : "N/A"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    Birthdate
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? `${beneficiary.birthDate}`
                                                        : "N/A"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    Civil Status
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? `${beneficiary.civilStatus}`
                                                        : "N/A"}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div
                                    className="card elevation-2"
                                    style={{
                                        borderTop: "4px solid #292726",
                                        borderRadius: "15px",
                                    }}
                                >
                                    <div className="card-header">
                                        <div className="card-title font-weight-bold">
                                            <BsIcons.BsFillPeopleFill
                                                className="text-lg mb-1 mr-2"
                                                style={{ color: "#2be0a9" }}
                                            />
                                            Spouse/Common-law Partner
                                            Information
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <ul class="list-group list-group-unbordered mb-3">
                                            <li
                                                class="list-group-item border-top-0"
                                                style={{ lineHeight: "15px" }}
                                            >
                                                <span className="text-muted">
                                                    Spouse/Common-law Name{" "}
                                                    <br />
                                                    <small className="text-xs mt-0">
                                                        <i>
                                                            First, Middle, Last
                                                            Name and Suffix
                                                        </i>
                                                    </small>
                                                </span>
                                                <span class="float-right font-weight-bold">
                                                    {spouse
                                                        ? `${
                                                              spouse.spouseFirstName ||
                                                              "N/A"
                                                          } ${
                                                              spouse.spouseMiddleName ||
                                                              ""
                                                          } ${
                                                              spouse.spouseLastName ||
                                                              ""
                                                          } ${
                                                              spouse.spouseSuffix ||
                                                              ""
                                                          }`
                                                        : "N/A"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    Sex
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {spouse
                                                        ? `${
                                                              spouse.spouseSex ||
                                                              "N/A"
                                                          }`
                                                        : "N/A"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    Birthdate
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {spouse
                                                        ? `${
                                                              spouse.spouseAge ||
                                                              "N/A"
                                                          }`
                                                        : "N/A"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    Civil Status
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {spouse
                                                        ? `${
                                                              spouse.spouseCivilStatus ||
                                                              "N/A"
                                                          }`
                                                        : "N/A"}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div
                                    className="card elevation-2"
                                    style={{
                                        borderTop: "4px solid #292726",
                                        borderRadius: "15px",
                                    }}
                                >
                                    <div className="card-header">
                                        <div className="card-title font-weight-bold">
                                            <BsIcons.BsPatchExclamationFill
                                                className="text-lg mb-1 mr-2"
                                                style={{ color: "#2be0a9" }}
                                            />
                                            General Information
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <ul class="list-group list-group-unbordered mb-3">
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    Tenurial Status
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? `${beneficiary.tenurialStatus}`
                                                        : "N/A"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    Pre-Qualification Number
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? `${beneficiary.pqNumber}`
                                                        : "N/A"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    Batch Number
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? `${beneficiary.batchNumber}`
                                                        : "N/A"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    Relocation Date
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? `${beneficiary.relocationDate}`
                                                        : "N/A"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    Place Of Origin
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? `${beneficiary.PointOfOrigin.projectName}`
                                                        : "N/A"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    Resettlement Site
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? `${beneficiary.Resettlement.resettlementSiteName}`
                                                        : "N/A"}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div
                                    className="card elevation-2"
                                    style={{
                                        borderTop: "4px solid #292726",
                                        borderRadius: "15px",
                                    }}
                                >
                                    <div className="card-header">
                                        <div className="card-title font-weight-bold">
                                            <BsIcons.BsFillFileEarmarkBarGraphFill
                                                className="text-lg mb-1 mr-2"
                                                style={{ color: "#2be0a9" }}
                                            />
                                            Status
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <ul class="list-group list-group-unbordered mb-3">
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    EP Control Number
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? beneficiary.controlNumber
                                                        : "N/A"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    MQH-RR
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary &&
                                                    beneficiary.step > 1
                                                        ? "Printed"
                                                        : "N/A"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    EP Request Number
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? beneficiary.requestNumber
                                                        : "N/A"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    EP Request Status
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? beneficiary.status
                                                        : "N/A"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    Printed EP
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? beneficiary.epPrintStatus
                                                        : "No"}
                                                </span>
                                            </li>
                                            <li class="list-group-item border-top-0">
                                                <span className="text-muted">
                                                    Remarks
                                                </span>{" "}
                                                <span class="float-right font-weight-bold">
                                                    {beneficiary
                                                        ? beneficiary.remarks
                                                        : "N/A"}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default BeneficiaryInfo;
