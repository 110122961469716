import React, { useEffect } from "react";
import { useState } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import MHQRRS from "./pages/MHQRRS";
import BeneficiaryInfo from "./pages/BeneficiaryInfo";
import Beneficiary from "./pages/Beneficiary";
import Batch from "./pages/Batch";
import RequestTable from "./pages/RequestTable";
import EPstatus from "./pages/EPstatus";
import EPmonitoring from "./pages/EPmonitoring";
import EPDMS from "./pages/EPDMS";
import SummaryReport from "./pages/SummaryReport";
import ResettlementSite from "./pages/MQHRRS2";
import BatchDetails from "./pages/BatchDetails";
import RequestList from "./pages/Request";
import Ep from "./pages/Ep";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./components/ProtectedRoute";
import AccessDeniedPage from "./components/AccessDenied";
import Cookies from "js-cookie";

function App() {
    return (
        <HashRouter>
            <ToastContainer />
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="home" element={<Dashboard />} />
                <Route
                    path="users"
                    element={
                        <ProtectedRoute accessLevelRequired={0}>
                            <Users />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="mqhrrs"
                    element={
                        <ProtectedRoute accessLevelRequired={2}>
                            <MHQRRS />
                        </ProtectedRoute>
                    }
                />
                <Route path="/access-denied" element={<AccessDeniedPage />} />
                <Route
                    path="beneficiary"
                    element={
                        <ProtectedRoute accessLevelRequired={2}>
                            <Beneficiary />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="beneficiary-info/:id"
                    element={<BeneficiaryInfo />}
                />
                <Route path="batch" element={<Batch />} />
                <Route path="requesttable" element={<RequestTable />} />
                <Route path="epstatus" element={<EPstatus />} />
                <Route path="epmonitoring" element={<EPmonitoring />} />
                <Route path="epdms" element={<EPDMS />} />
                <Route path="summaryreport" element={<SummaryReport />} />
                <Route
                    path="request/:requestNumber"
                    element={<RequestList />}
                />
                <Route path="resettlementsite" element={<ResettlementSite />} />
                <Route path="ep" element={<Ep />} />
                <Route
                    path="batchdetails/:batchNumber"
                    element={<BatchDetails />}
                />
            </Routes>
        </HashRouter>
    );
}

export default App;
