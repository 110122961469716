const fxaddUsers = async (
    accessLevel,
    // selectedDistrictOffice,
    // selectedRegionalOffice,

    // regionalOfficeID,
    // districtOfficeID,
    districtOfficeID,
    regionalOfficeID,
    regionalOffice,
    districtOffice,
    positionName,
    employeeNo,
    firstName,
    middleName,
    lastName,
    password,
    mobileNo,
    email,
    status
) => {
    if (
        // accessLevel === "" ||
        // regionalOffice === "" ||
        // districtOffice === "" ||
        // positionName === "" ||
        employeeNo === "" ||
        firstName === "" ||
        // middleName === "" ||
        lastName === "" ||
        password === "" ||
        mobileNo === "" ||
        email === ""
    ) {
        return "1111";
    }
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    try {
        let res = await fetch("https://api.nha.gov.ph/ep/trx_addusers", {
            mode: "cors",
            method: "POST",
            headers: headers,
            credentials: "include",
            body: JSON.stringify({
                trx_accessLevel: accessLevel,
                trx_regionalOfficeID: regionalOfficeID,
                trx_districtOfficeID: districtOfficeID,
                trx_regionalOffice: regionalOffice,
                trx_districtOffice: districtOffice,
                // regionalOffice: selectedRegionalOffice,
                // districtOffice: selectedDistrictOffice,
                trx_positionName: positionName,
                trx_employeeNo: employeeNo,
                trx_firstName: firstName,
                trx_middleName: middleName,
                trx_lastName: lastName,
                trx_password: password,
                trx_mobileNo: mobileNo,
                trx_email: email,
                trx_status: status,
            }),
        });

        if (res.status === 200) {
            return "0000";
        } else {
            return "1111";
        }
    } catch (err) {
        console.error(err);
    }

    return "0000";
};

export default fxaddUsers;
