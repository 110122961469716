import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRoute = ({ accessLevelRequired, children }) => {
    const [accessLevel, setAccessLevel] = useState(null); // Initialize state for access level
    const [isLoading, setIsLoading] = useState(true); // State to manage loading

    useEffect(() => {
        const accessLevelCookie = Cookies.get("accessLevel");

        // Debugging line

        if (accessLevelCookie) {
            setAccessLevel(Number(accessLevelCookie));
        }

        setIsLoading(false); // Once cookie is retrieved, loading is complete
    }, []);

    // Handle loading state
    if (isLoading) {
        return <div>Loading...</div>; // Render a loading spinner or message
    }

    // If accessLevel is still null after loading (e.g., cookie not set)
    if (accessLevel === null) {
        return <Navigate to="/login" />; // Redirect to login or another page if access level is not available
    }

    // Check if the user has the required access level
    if (accessLevel === accessLevelRequired) {
        return children;
    } else {
        return <Navigate to="/access-denied" />;
    }
};

export default ProtectedRoute;
