import React, { useState } from "react";
import "./Modal.css";
import { Mqhrr } from "./Mqhrr/Mqhrr";
import { Link, useNavigate } from "react-router-dom";
import { BsPrinter } from "react-icons/bs";

const Modal = ({
    isOpen,
    onClose,
    children,
    batchNumber,
    formData,
    selectedIds,
    inRequest,
    print,
}) => {
    const [isClosing, setIsClosing] = useState(false);

    const handleClose = () => {
        onClose();
    };

    const handleModalClick = (event) => {
        // Prevent the click event from propagating to the parent (modal-overlay)
        event.stopPropagation();
    };

    const handleOverlayClick = (event) => {
        // Check if the click occurred outside of the modal content or on the close button
        if (
            event.target.classList.contains("modal-overlay") ||
            event.target.getAttribute("id") === "closeButton"
        ) {
            onClose();
        }
    };
    const navigate = useNavigate();

    const handleCancel = () => {
        onClose();
    };

    const handlePrint = () => {
        Mqhrr({ batchNumber, formData });
        onClose(); // Close the modal after printing
    };


    return isOpen ? (
        <div className={`modal ${isClosing ? "closing" : ""}`}>
            <div className="modal-overlay" onClick={handleOverlayClick}>
                <div className="modal-content" onClick={handleModalClick}>
                    {children}
                    <div className="flex justify-center mt-4">
                        {!print ? (
                            <button
                                onClick={handlePrint}
                                className="button-9"
                                disabled={
                                    !batchNumber ||
                                    Object.values(formData).some(
                                        (value) => !value
                                    )
                                }
                            >
                                Print
                            </button>
                        ) : null}
                    </div>
                    {/* {inRequest ? (
                        <button
                            onClick={handleNextEp}
                            disabled={Object.values(formData).some(
                                (value) => !value
                            )}
                        >
                            Print2
                        </button>
                    ) : null} */}
                    <div className="flex justify-center mt-2">
                        <button className="button-9" onClick={handleCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default Modal;
