import React, { useState } from "react";
import axios from "axios";
import "../styles/Form.css"; // Assuming you have a CSS file for styling
import { BsUpload } from "react-icons/bs";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const UploadPdf = async (selectedFile, requestNumber) => {
    if (!selectedFile || !requestNumber) {
        toast.error("Please select a file and provide a request number.");
        return;
    }
    try {
        const formData = new FormData();
        formData.append("file", selectedFile); // Append the selected file to FormData
        formData.append("requestNumber", requestNumber);

        // Log FormData entries

        const res = await axios.post(
            "https://api.nha.gov.ph/ep/upload",
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data", // Ensure the correct content type
                },
                withCredentials: true, // Include this if you need to send cookies along with the request
            }
        );

        toast.success("File Uploaded Successfully");
    } catch (error) {
        console.error(error);
        toast.error("File upload failed. Please try again later.");
    }
};

export default UploadPdf;
