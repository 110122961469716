import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import axios from "axios";

const EPStatus = ({ selectedRegionalOffice, startDate, endDate }) => {
    const chartContainer = useRef(null);
    const [loading, setLoading] = useState(true);
    const [chartInstance, setChartInstance] = useState(null);
    const [chartData, setChartData] = useState(null);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                "https://api.nha.gov.ph/ep/trx_all_data",
                { params: { startDate, endDate, selectedRegionalOffice } }
            );
            const responseData = response.data;

            // Extract labels and datasets from responseData
            const labels = responseData.map((entry) => entry.regionalOffice);
            const printStatuses = new Set();
            responseData.forEach((entry) => {
                entry.counts.forEach((count) => {
                    printStatuses.add(count.epPrintStatus);
                });
            });

            const colors = {
                Issued: "rgba(6, 165, 13, 0.6)",
                Spoiled: "rgba(184, 129, 184, 0.6)",
                Cancelled: "rgba(235, 87, 87, 0.6)",
                Deferred: "rgba(255, 180, 31, 0.6)",
            };

            const backgroundColors = {
                Issued: "rgba(6, 165, 13, 1)",
                Spoiled: "rgba(184, 129, 184, 1)",
                Cancelled: "rgba(235, 87, 87, 1)",
                Deferred: "rgba(255, 180, 31, 1)",
            };

            const datasets = Array.from(printStatuses).map((status) => {
                const data = responseData.map((entry) => {
                    const countEntry = entry.counts.find(
                        (count) => count.epPrintStatus === status
                    );
                    return countEntry ? countEntry.count : 0;
                });
                return {
                    label: status,
                    data: data,
                    backgroundColor: colors[status],
                    borderColor: backgroundColors[status],
                    borderWidth: 1,
                };
            });

            setChartData({
                labels: labels,
                datasets: datasets,
            });

            setLoading(false);
        } catch (error) {
            console.error("Error Fetching requests:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [startDate, endDate, selectedRegionalOffice]);

    useEffect(() => {
        if (chartInstance) {
            chartInstance.destroy(); // Destroy previous chart instance
        }

        if (chartContainer.current && chartData) {
            const ctx = chartContainer.current.getContext("2d");

            setChartInstance(
                new Chart(ctx, {
                    type: "bar",
                    data: chartData,
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                beginAtZero: true,
                                title: {
                                    display: true,
                                    text: "Count",
                                },
                                grid: {
                                    color: "#e0e0e0",
                                },
                            },
                            x: {
                                title: {
                                    display: true,
                                    text: "Regional Office",
                                },
                                grid: {
                                    display: false,
                                },
                            },
                        },
                        plugins: {
                            legend: {
                                display: true,
                                position: "top",
                                labels: {
                                    font: {
                                        size: 14,
                                    },
                                },
                            },
                        },
                        layout: {
                            padding: {
                                top: 20,
                                right: 20,
                                bottom: 20,
                                left: 20,
                            },
                        },
                    },
                })
            );

            return () => {
                if (chartInstance) {
                    chartInstance.destroy();
                }
            };
        }
    }, [chartData]);

    if (!chartData || chartData.labels.length === 0) {
        return <p>No data available for the selected filters.</p>;
    } else {
        return (
            <div style={{ height: "100%" }}>
                <canvas ref={chartContainer} />
            </div>
        );
    }
};

export default EPStatus;
