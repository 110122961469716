import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import axios from "axios";

const EPmonitoring = () => {
    const [startDate, setStartDate] = useState(new Date("2000-01-01"));
    const [endDate, setEndDate] = useState(new Date("9999-01-01"));
    const [regionalOffices, setRegionalOffices] = useState([]);
    useEffect(() => {
        fetchData();
    }, [startDate, endDate]);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                "https://api.nha.gov.ph/ep/trx_all_data",
                {
                    params: { startDate, endDate },
                }
            );
            const responseData = response.data;
            setRegionalOffices(responseData); // Set regionalOffices directly to responseData
            if (Array.isArray(responseData)) {
                // Extracting EP print statuses dynamically
                const printStatuses = new Set();
                responseData.forEach((entry) => {
                    entry.counts.forEach((countEntry) => {
                        printStatuses.add(countEntry.epPrintStatus);
                    });
                });
            }
        } catch (error) {
            console.error("Error Fetching requests:", error);
        }
    };

    return (
        <div>
            <Header />
            <Sidebar />

            <div className="content-wrapper pl-2 pr-2 pb-3">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h1>Entry Pass Monitoring Report</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="pl-2 pr-2">
                    <div className="container-fluid">
                        <div
                            className="card elevation-2"
                            style={{
                                borderTop: "4px solid #292726",
                                borderRadius: "15px",
                            }}
                        >
                            <div className="card-header pb-1">
                                <p className="mb-0">
                                    National Housing Authority
                                </p>
                                <p className="mb-0">
                                    Resettlement & Development Services
                                    Department
                                </p>
                                <p className="mb-0">
                                    Relocation & Resettlement Monitoring
                                    Division
                                </p>
                                <h5 className="font-weight-bold pt-2">
                                    INVENTORY REPORT ON THE STATUS OF ENTRY PASS
                                    RELEASED
                                </h5>
                            </div>

                            <div
                                className="card-body table-responsive p-0"
                                style={{ height: 400 }}
                            >
                                <table className="table table-head-fixed table-hover table-bordered text-wrap">
                                    <thead
                                        style={{
                                            borderBottom: "2px solid #ccc",
                                        }}
                                    >
                                        <tr>
                                            <th
                                                colspan="2"
                                                className="text-center"
                                                style={{
                                                    verticalAlign: "middle",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                NO. OF EP RELEASED <br /> (a)
                                            </th>
                                            <th
                                                colspan="5"
                                                className="text-center"
                                                style={{
                                                    verticalAlign: "middle",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                LIQUIDATED <br /> (b)
                                            </th>
                                            <th
                                                rowspan="2"
                                                className="text-middle text-center"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                BALANCE <br /> (d-i=j)
                                            </th>
                                            <th
                                                rowspan="2"
                                                className="text-middle text-center"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                REMARKS <br /> (k)
                                            </th>
                                        </tr>

                                        <tr className="text-center">
                                            <th
                                                rowspan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                REGION <br /> (c)
                                            </th>
                                            <th
                                                rowspan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                RELEASED <br /> (d)
                                            </th>
                                            <th
                                                rowspan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                ISSUED <br /> (e)
                                            </th>
                                            <th
                                                rowspan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                CANCELLED <br /> (f)
                                            </th>
                                            <th
                                                rowspan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                DEFERRED <br /> (g)
                                            </th>
                                            <th
                                                rowspan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                SPOILED <br /> (h)
                                            </th>
                                            <th
                                                rowspan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                SUB-TOTAL <br /> (i)
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {regionalOffices.map(
                                            (office, index) => (
                                                <>
                                                    <tr key={index + 1}>
                                                        <td className="text-middle">
                                                            {
                                                                office.regionalOffice
                                                            }
                                                        </td>
                                                        <td className="text-middle">
                                                            {office.epCount}
                                                        </td>
                                                        <td className="text-middle">
                                                            {office.counts.find(
                                                                (item) =>
                                                                    item.epPrintStatus ===
                                                                    "Issued"
                                                            )?.count || 0}
                                                        </td>
                                                        <td className="text-middle">
                                                            {office.counts.find(
                                                                (item) =>
                                                                    item.epPrintStatus ===
                                                                    "Cancelled"
                                                            )?.count || 0}
                                                        </td>
                                                        <td className="text-middle">
                                                            {office.counts.find(
                                                                (item) =>
                                                                    item.epPrintStatus ===
                                                                    "Deferred"
                                                            )?.count || 0}
                                                        </td>
                                                        <td className="text-middle text-center">
                                                            {office.counts.find(
                                                                (item) =>
                                                                    item.epPrintStatus ===
                                                                    "Spoiled"
                                                            )?.count || 0}
                                                        </td>
                                                        <td className="text-middle">
                                                            {office.counts.reduce(
                                                                (acc, curr) =>
                                                                    acc +
                                                                    curr.count,
                                                                0
                                                            )}
                                                        </td>
                                                        <td className="text-middle">
                                                            {" "}
                                                            {office.counts.reduce(
                                                                (acc, curr) =>
                                                                    acc +
                                                                    curr.count,
                                                                0
                                                            ) -
                                                                office.epCount}{" "}
                                                        </td>
                                                        <td className="text-middle text-center p-0">
                                                            {office.remarks}
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EPmonitoring;
