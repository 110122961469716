import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Textarea from "@mui/joy/Textarea";
import Button from "@mui/material/Button";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
const labelStyle = {
    fontWeight: "normal",
};

const EPstatus = () => {
    const [openEdit, setOpenEdit] = useState(false);
    const [modalData, setModalData] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [status, setStatus] = useState("");
    const [statusOfAward, setStatusOfAward] = useState("");
    const [epRemarks, setEpRemarks] = useState("");
    const [rdsdRemarks, setRdsdRemarks] = useState("");
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [accessLevel, setAccessLevel] = useState();
    const [awardStatus, setAwardStatus] = useState("");

    useEffect(() => {
        const al = Cookies.get("accessLevel");
        setAccessLevel(al);
    }, []);
    const fetchPrintedStatusBeneficiaries = async () => {
        try {
            const response = await axios.get(
                "https://api.nha.gov.ph/ep/trx_ep_printed_status",
                { withCredentials: true }
            );

            if (response.status === 200) {
                setData(response.data.data);
            } else {
                console.error(
                    "Failed to fetch beneficiaries:",
                    response.data.message
                );
            }
        } catch (error) {
            console.error("Error fetching beneficiaries:", error);
        }
    };

    const fetchOneBeneficiary = async (id) => {
        try {
            const response = await axios.get(
                `https://api.nha.gov.ph/ep/trx_one_beneficiary/${id}`,
                { withCredentials: true }
            );

            if (response.status === 200) {
                setModalData(response.data);
                setSelectedDate(dayjs(response.data.epReleaseDate));
                setStatus(response.data.epPrintStatus);
                setStatusOfAward(response.data.statusOfAward);
                setEpRemarks(response.data.epRemarks);
                setRdsdRemarks(response.data.rdsdRemarks);
                setOpenEdit(true); // Open modal after fetching data
            } else {
                console.error(
                    "Failed to fetch beneficiary:",
                    response.data.message
                );
            }
        } catch (error) {
            console.error("Error fetching beneficiary:", error);
        }
    };
    const updateBeneficiaryDetails = async (
        id,
        epReleaseDate,
        epPrintStatus,
        statusOfAward,
        epRemarks,
        rdsdRemarks
    ) => {
        try {
            const response = await axios.put(
                `https://api.nha.gov.ph/ep/trx_update_beneficiaryDetails/${id}`,
                {
                    epReleaseDate,
                    epPrintStatus,
                    statusOfAward,
                    epRemarks,
                    rdsdRemarks,
                }
            );

            if (response.data.success) {
                toast.success("Beneficiary details updated successfully");
                fetchPrintedStatusBeneficiaries(); // Refresh the list after update
                setOpenEdit(false); // Close the modal after successful update
            } else {
                console.error(
                    "Failed to update beneficiary details:",
                    response.data.message
                );
            }
        } catch (error) {
            console.error("Error updating beneficiary details:", error);
        }
    };

    useEffect(() => {
        fetchPrintedStatusBeneficiaries();
    }, []);

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleStatusOfAwardChange = (event) => {
        setStatusOfAward(event.target.value);
    };

    const handleEpRemarksChange = (event) => {
        setEpRemarks(event.target.value);
    };

    const handleRdsdRemarksChange = (event) => {
        setRdsdRemarks(event.target.value);
    };

    const handleSubmit = () => {
        updateBeneficiaryDetails(
            modalData.id,
            selectedDate,
            status,
            statusOfAward,
            epRemarks,
            rdsdRemarks
        );
    };
    return (
        <div>
            <Header />
            <Sidebar />

            <Modal
                open={openEdit}
                onClose={() => setOpenEdit(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div sx={style} className="modal-viewInfo card userModal">
                    <div className="card-header">
                        <div className="card-title font-weight-bold">
                            Update Data
                        </div>
                    </div>
                    <div className="card-body">
                        <div>
                            <h5 className="font-weight-bold mb-0">
                                {modalData.lastName}, {modalData.firstName}{" "}
                                {modalData.middleName}
                            </h5>
                            <ul className="list-group list-group-unbordered mb-3">
                                <li className="list-group-item border-top-0 pt-1 text-sm">
                                    {/* <span>
                                        Sequence No. &nbsp;{" "}
                                        <b>{modalData.sequenceNo}</b>
                                    </span> */}
                                    <span className="float-right">
                                        Control No. &nbsp;{" "}
                                        <b>{modalData.controlNumber}</b>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <label className="mb-0">Date Relocated</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                    <DesktopDatePicker
                                        className="w-100"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                margin="normal"
                                            />
                                        )}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>

                            <label className="mb-0 mt-3">Status</label>
                            <FormControl size="small" fullWidth>
                                <Select
                                    value={status}
                                    onChange={handleStatusChange}
                                >
                                    <MenuItem value="">Select Status</MenuItem>
                                    <MenuItem value="Issued">Issued</MenuItem>
                                    <MenuItem value="Cancelled">
                                        Cancelled
                                    </MenuItem>
                                    <MenuItem value="Deferred">
                                        Deferred
                                    </MenuItem>
                                    <MenuItem value="Spoiled">Spoiled</MenuItem>
                                </Select>
                            </FormControl>

                            <label className="mb-0 mt-3">Status of Award</label>
                            <FormControl size="small" fullWidth>
                                <Select
                                    value={statusOfAward}
                                    onChange={handleStatusOfAwardChange}
                                    disabled={status !== "Issued"}
                                >
                                    <MenuItem value="">
                                        Select Status Of Award
                                    </MenuItem>
                                    <MenuItem value="Not Awarded">
                                        Not Awarded
                                    </MenuItem>
                                    <MenuItem value="Awarded">Awarded</MenuItem>
                                </Select>
                            </FormControl>

                            <label className="mb-0 mt-3">Remarks</label>
                            <Textarea
                                minRows={2}
                                onChange={handleEpRemarksChange}
                                variant="outlined"
                                value={epRemarks}
                                disabled={accessLevel != 1}
                            />

                            <label className="mb-0 mt-3">RDSD Remarks</label>
                            <Textarea
                                minRows={2}
                                value={rdsdRemarks}
                                onChange={handleRdsdRemarksChange}
                                variant="outlined"
                                disabled={accessLevel != 0}
                            />
                        </div>
                    </div>

                    <div className="card-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="w-100 text-dark"
                            size="large"
                            onClick={handleSubmit}
                            style={{
                                backgroundColor: "#2be0a9",
                                borderRadius: "50px",
                            }}
                        >
                            SAVE CHANGES
                        </Button>
                    </div>
                </div>
            </Modal>

            <div className="content-wrapper pl-2 pr-2 pb-3">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h1>Entry Pass Status</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pl-2 pr-2">
                    <div className="container-fluid">
                        <div className="mt-3">
                            <div style={{ display: "flex" }}>
                                <p className="pt-2 mr-2">
                                    Search by Control Number:
                                </p>
                                <TextField
                                    placeholder="Search here"
                                    className="mb-3"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={searchTerm}
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                    }}
                                    size="small"
                                    InputLabelProps={{ style: labelStyle }}
                                    type="text"
                                />
                            </div>
                            <div
                                className="card elevation-2"
                                style={{
                                    borderTop: "4px solid #292726",
                                    borderRadius: "15px",
                                }}
                            >
                                <div className="card-header">
                                    <h3 className="card-title font-weight-bold">
                                        List of Beneficiaries with Printed Entry
                                        Pass
                                    </h3>
                                </div>

                                <div
                                    className="card-body table-responsive p-0"
                                    style={{ height: 400 }}
                                >
                                    <div>
                                        <table className="table table-head-fixed table-hover table-bordered text-wrap">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Sequence No.</th>
                                                    <th>Control No.</th>
                                                    <th>Last Name</th>
                                                    <th>First Name</th>
                                                    <th>Middle Name</th>
                                                    <th>Date Relocated</th>
                                                    <th>Status</th>
                                                    <th>Status of Award</th>
                                                    <th>Remarks</th>
                                                    <th>RDSD Remarks</th>
                                                    <th className="text-center">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {Array.isArray(data) &&
                                                data.length > 0
                                                    ? data.map(
                                                          (item, index) => (
                                                              <tr
                                                                  key={index}
                                                                  className="td-hover"
                                                              >
                                                                  <td className="text-middle"></td>
                                                                  <td className="text-middle">
                                                                      {index +
                                                                          1}
                                                                  </td>
                                                                  <td className="text-middle">
                                                                      {
                                                                          item.controlNumber
                                                                      }
                                                                  </td>
                                                                  <td className="text-middle">
                                                                      {
                                                                          item.lastName
                                                                      }
                                                                  </td>
                                                                  <td className="text-middle">
                                                                      {
                                                                          item.firstName
                                                                      }
                                                                  </td>
                                                                  <td className="text-middle">
                                                                      {
                                                                          item.middleName
                                                                      }
                                                                  </td>
                                                                  <td className="text-middle">
                                                                      {
                                                                          item.epReleaseDate
                                                                      }
                                                                  </td>
                                                                  <td className="text-middle">
                                                                      {
                                                                          item.epPrintStatus
                                                                      }
                                                                  </td>
                                                                  <td className="text-middle">
                                                                      {
                                                                          item.statusOfAward
                                                                      }
                                                                  </td>
                                                                  <td className="text-middle">
                                                                      {
                                                                          item.epRemarks
                                                                      }
                                                                  </td>
                                                                  <td className="text-middle">
                                                                      {
                                                                          item.rdsdRemarks
                                                                      }
                                                                  </td>
                                                                  <td className="text-center p-0">
                                                                      <Tooltip title="View Info">
                                                                          <IconButton>
                                                                              <a
                                                                                  href={`/#/beneficiary-info/${item.id}`}
                                                                              >
                                                                                  <RemoveRedEyeRoundedIcon
                                                                                      className="text-lg"
                                                                                      style={{
                                                                                          color: "#34a8ff",
                                                                                      }}
                                                                                  />
                                                                              </a>
                                                                          </IconButton>
                                                                      </Tooltip>

                                                                      <Tooltip title="Update Data">
                                                                          <IconButton
                                                                              onClick={() =>
                                                                                  fetchOneBeneficiary(
                                                                                      item.id
                                                                                  )
                                                                              }
                                                                          >
                                                                              <BorderColorIcon
                                                                                  className="text-lg"
                                                                                  style={{
                                                                                      color: "#2be0a9",
                                                                                  }}
                                                                              />
                                                                          </IconButton>
                                                                      </Tooltip>
                                                                  </td>
                                                              </tr>
                                                          )
                                                      )
                                                    : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div
                                    className="card-footer"
                                    style={{
                                        borderBottomLeftRadius: "15px",
                                        borderBottomRightRadius: "15px",
                                    }}
                                >
                                    {/* <div className="row cardFooterCenter">
                                        <div className="col-sm-6">
                                            <label>1</label>{" "}
                                            <span className="text-muted">
                                                of
                                            </span>{" "}
                                            <label>3</label>
                                            &nbsp;
                                            <IconButton>
                                                <ArrowBackIosIcon className="text-md pl-1" />
                                            </IconButton>
                                            <IconButton>
                                                <ArrowForwardIosRoundedIcon className="text-md pl-1" />
                                            </IconButton>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <ToastContainer />
        </div>
    );
};

export default EPstatus;
