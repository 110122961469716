import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import * as BsIcons from "react-icons/bs";
// mui
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import Checkbox from "@mui/material/Checkbox";
// mui icon button and icons
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import axios from "axios";
// import XLSX from "xlsx-js-style";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// accordion
const AccordionStyle = {
    "&:before": {
        backgroundColor: "transparent !important",
    },
};

// checkbox
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Beneficiary = ({
    isInRequestComponent = false,
    onDataFromChild = () => {},
    requestNumberFromRequests,
    accessLevel: propAccessLevel,
}) => {
    // mui
    const selectLabelStyle = {
        fontWeight: "normal",
    };
    const labelStyle = {
        fontWeight: "normal",
    };
    // mui select

    const [Sex, setSex] = useState("");
    const handleSex = (event) => {
        setSex(event.target.value);
    };

    const [CivilStatus, setCivilStatus] = useState("");
    const handleCivilStatus = (event) => {
        setCivilStatus(event.target.value);
    };

    const [TenurialStatus, setTenurialStatus] = useState("");
    const handleTenurialStatus = (event) => {
        setTenurialStatus(event.target.value);
    };

    // stepper for edit user
    const [currentStep, setCurrentStep] = useState(1);
    const [errors, setErrors] = useState(false);

    // popup
    const [openUpload, setopenUpload] = useState(false);
    const uploadtoggle = () => setopenUpload(!openUpload);

    const uploadclose = () => setopenUpload(false);

    const [isUpload, setIsUpload] = useState(false);
    const utoggle = () => setIsUpload(!isOpen);

    // modal
    const [openEditUserModal, setopenEditUserModal] = useState(false);

    // filter
    const [openFilter, setopenFilter] = useState(false);
    const filterMenu = () => setopenFilter(!openFilter);

    // popper
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const toggleDropdown = () => {
        setIsPopperOpen(!isPopperOpen);
    };

    const [showOpen, setShowOpen] = useState(false);
    const crDown = () => setShowOpen(!showOpen);

    const [userList, setUserList] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);

    const toggleAdd = () => {
        setAddTrue(!addTrue);
    };
    const toggleAdd2 = () => {
        setAddTrue2(!addTrue2);
    };

    const [addTrue, setAddTrue] = useState(false);
    const [addTrue2, setAddTrue2] = useState(false);
    const [data, setData] = useState([]);
    // popup
    const [openModal, setopenModal] = useState(false);
    const modtoggle = () => setopenModal(!openModal);
    const handleclose = () => setopenModal(false);

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const itemsPerPage = 1000; // Adjust the number of items per page as needed
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [ExcelItems, setExcelItems] = useState([]);
    const location = useLocation();
    const state = location.state;

    // Access the data from EntryPass and ResettlementSite
    const pointOfOriginId = state ? state.entryPassId : null;
    const resettlementSiteId = state ? state.resettlementId : null;
    const [selectedIds, setSelectedIds] = useState([]);
    const [regionalOffice, setRegionalOffice] = useState(null);
    const [districtOffice, setDistrictOffice] = useState(null);
    const [accessLevel, setAccessLevel] = useState(() => {
        const value = propAccessLevel ?? Cookies.get("accessLevel");
        return value;
    });

    // const userId = Cookies.get("userId");
    const [formData, setFormData] = useState({
        lastName: "",
        firstName: "",
        middleName: "",
        suffix: "",
        sex: "",
        birthDate: dayjs(null),
        civilStatus: "",
        tagNumber: "",
        tenurialStatus: "",
        pqNumber: "",
        hhpwd: false,
        solo: false,
        spouseLastName: null,
        spouseFirstName: null,
        spouseMiddleName: null,
        spouseSuffix: null,
        spouseSex: null,
        spouseAge: dayjs(null),
        spousepwd: false,
        spouseCivilStatus: null,
        secondSpouseLastName: null,
        secondSpouseFirstName: null,
        secondSpouseMiddleName: null,
        secondSpouseSuffix: null,
        secondSpouseSex: null,
        secondSpouseAge: dayjs(null),
        secondSpouseCivilStatus: null,
        spouseCivilStatus: null,
        previousSpouseLastName: null,
        previousSpouseFirstName: null,
        previousSpouseMiddleName: null,
        previousSpouseSuffix: null,
        previousSpouseSex: null,
        previousSpouseAge: dayjs(null),
        previousSpouseCivilStatus: null,
        seniorLastName: null,
        seniorFirstName: null,
        seniorMiddleName: null,
        seniorSuffix: null,
        seniorSex: null,
        seniorAge: dayjs(null),
        seniorCivilStatus: null,
        seniorpwd: null,
        seniorsolo: null,
        batchNumber: "",
        relocationDate: dayjs(null),
        remarks: "",
    });
    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.firstName) {
            newErrors.firstName = "First name is required";
            isValid = false;
        } else if (!/^[a-zA-ZñÑ\s-]+$/.test(formData.firstName)) {
            newErrors.firstName = "First name must contain only letters";
            isValid = false;
        }

        if (
            formData.middleName &&
            !/^[a-zA-ZñÑ-\s]+$/.test(formData.middleName)
        ) {
            newErrors.middleName =
                "Middle name must contain only letters and hyphens";
            isValid = false;
        }

        if (!formData.lastName) {
            newErrors.lastName = "Last name is required";
            isValid = false;
        } else if (!/^[a-zA-ZñÑ-\s]+$/.test(formData.lastName)) {
            newErrors.lastName = "Last name must contain only letters";
            isValid = false;
        }

        if (!formData.sex) {
            newErrors.sex = "Sex is required";
            isValid = false;
        }

        if (!formData.birthDate) {
            newErrors.birthDate = "Birth date is required";
            isValid = false;
        }

        if (!formData.civilStatus) {
            newErrors.civilStatus = "Civil status is required";
            isValid = false;
        }

        if (!formData.tagNumber) {
            newErrors.tagNumber = "Tag number is required";
            isValid = false;
        }

        if (!formData.tenurialStatus) {
            newErrors.tenurialStatus = "Tenurial status is required";
            isValid = false;
        }

        if (!formData.pqNumber) {
            newErrors.pqNumber = "PQ number is required";
            isValid = false;
        }

        if (!formData.batchNumber) {
            newErrors.batchNumber = "Batch number is required";
            isValid = false;
        }

        if (!formData.relocationDate) {
            newErrors.relocationDate = "Relocation date is required";
            isValid = false;
        }
        // } else if (!dayjs(formData.relocationDate).isValid()) {
        //     newErrors.relocationDate = "Invalid relocation date";
        //     isValid = false;
        // }
        // console.log("valid valid", formData);
        // console.log([newErrors], "p0000000");
        setErrors(newErrors);
        return isValid;
    };

    //Import

    // Getting excel data from input type (file)
    // const readExcel = (file) => {
    //     const promise = new Promise((resolve, reject) => {
    //         const fileReader = new FileReader();
    //         fileReader.readAsArrayBuffer(file);
    //         fileReader.onload = (e) => {
    //             const bufferArray = e.target.result;

    //             const wb = XLSX.read(bufferArray);
    //             const wsname = wb.SheetNames[0];
    //             const ws = wb.Sheets[wsname];
    //             const range = `A19:Z${ws["!ref"].split(":")[1].slice(1)}`;
    //             const data = XLSX.utils.sheet_to_json(ws, {
    //                 range,
    //                 header: 1,
    //             });
    //             resolve(data);
    //             // setreloadtable(file);
    //         };

    //         fileReader.onerror = (error) => {
    //             // reject(error);
    //         };
    //     });

    //     promise.then((dataXlsx) => {
    //         //Function
    //         setExcelItems(dataXlsx);
    //     });
    // };
    const fetchData = async () => {
        try {
            const response = await axios.get(
                "https://api.nha.gov.ph/ep/trx_beneficiary_spouse_data",
                {
                    withCredentials: true, // Correctly placed inside the options object
                }
            );

            if (response.status === 200) {
                const allBeneficiaries = response.data;
                let beneficiariesToSet = allBeneficiaries;

                if (isInRequestComponent) {
                    beneficiariesToSet = allBeneficiaries.filter(
                        (beneficiary) =>
                            beneficiary.requestNumber ==
                            requestNumberFromRequests
                    );
                    const filteredIds = beneficiariesToSet.map(
                        (beneficiary) => beneficiary.id
                    );
                    setFilteredBeneficiaryIds(filteredIds);
                }

                setData(beneficiariesToSet);
                setFilteredBeneficiaries(beneficiariesToSet);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle the error here
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form data
        if (resettlementSiteId === null || pointOfOriginId === null) {
            toast.error("Check Resettlement Site and Point Of Origin");
            return;
        }

        const endpoint = "https://api.nha.gov.ph/ep/trx_beneficiary_spouse";
        const data = {
            beneficiaryData: {
                lastName: formData.lastName,
                firstName: formData.firstName,
                middleName: formData.middleName,
                suffix: formData.suffix,
                sex: formData.sex,
                birthDate: formData.birthDate || null,
                civilStatus: formData.civilStatus,
                tagNumber: formData.tagNumber,
                tenurialStatus: formData.tenurialStatus,
                pqNumber: formData.pqNumber,
                batchNumber: formData.batchNumber,
                relocationDate: formData.relocationDate,
                remarks: formData.remarks,
                hhpwd: formData.hhpwd,
                solo: formData.solo,
            },
            spouseData: {
                spouseLastName: formData.spouseLastName,
                spouseFirstName: formData.spouseFirstName,
                spouseMiddleName: formData.spouseMiddleName,
                spouseSuffix: formData.spouseSuffix,
                spouseSex: formData.spouseSex,
                spouseAge: formData.spouseAge || null,
                spouseCivilStatus: formData.spouseCivilStatus,
                spousepwd: formData.spousepwd,
            },
            secondSpouseData: {
                secondSpouseLastName: formData.secondSpouseLastName,
                secondSpouseFirstName: formData.secondSpouseFirstName,
                secondSpouseMiddleName: formData.secondSpouseMiddleName,
                secondSpouseSuffix: formData.secondSpouseSuffix,
                secondSpouseSex: formData.secondSpouseSex,
                secondSpouseAge: formData.secondSpouseAge || null,
                secondSpouseCivilStatus: formData.secondSpouseCivilStatus,
            },
            previousSpouseData: {
                previousSpouseLastName: formData.previousSpouseLastName,
                previousSpouseFirstName: formData.previousSpouseFirstName,
                previousSpouseMiddleName: formData.previousSpouseMiddleName,
                previousSpouseSuffix: formData.previousSpouseSuffix,
                previousSpouseSex: formData.previousSpouseSex,
                previousSpouseAge: formData.previousSpouseAge || null,
                previousSpouseCivilStatus: formData.previousSpouseCivilStatus,
            },
            seniorData: {
                seniorLastName: formData.seniorLastName,
                seniorFirstName: formData.seniorFirstName,
                seniorMiddleName: formData.seniorMiddleName,
                seniorSuffix: formData.seniorSuffix,
                seniorSex: formData.seniorSex,
                seniorAge: formData.seniorAge || null,
                seniorCivilStatus: formData.seniorCivilStatus,
                seniorpwd: formData.seniorpwd,
                seniorsolo: formData.seniorsolo,
            },
            pointOfOriginId,
            resettlementSiteId,
            accessLevel,
        };
        try {
            const response = await axios.post(endpoint, data, {
                withCredentials: true, // Include credentials in the request
            });

            toast.success("Beneficiary successfully added");
            fetchData();

            setFormData({
                lastName: "",
                firstName: "",
                middleName: "",
                suffix: "",
                sex: "",
                birthDate: dayjs(null),
                civilStatus: "",
                tagNumber: "",
                tenurialStatus: "",
                pqNumber: "",
                hhpwd: false,
                solo: false,
                spouseLastName: "",
                spouseFirstName: "",
                spouseMiddleName: "",
                spouseSuffix: "",
                spouseSex: "",
                spouseAge: dayjs(null),
                spousepwd: false,
                spouseCivilStatus: "",
                secondSpouseLastName: "",
                secondSpouseFirstName: "",
                secondSpouseMiddleName: "",
                secondSpouseSuffix: "",
                secondSpouseSex: "",
                secondSpouseAge: dayjs(null),
                secondSpouseCivilStatus: "",
                previousSpouseLastName: "",
                previousSpouseFirstName: "",
                previousSpouseMiddleName: "",
                previousSpouseSuffix: "",
                previousSpouseSex: "",
                previousSpouseAge: dayjs(null),
                previousSpouseCivilStatus: "",
                seniorLastName: "",
                seniorFirstName: "",
                seniorMiddleName: "",
                seniorSuffix: "",
                seniorSex: "",
                seniorAge: dayjs(null),
                seniorCivilStatus: "",
                seniorpwd: false,
                seniorsolo: false,
                remarks: "",
                batchNumber: formData.batchNumber,
                relocationDate: dayjs(formData.relocationDate),
            });

            setAddTrue(false);
            setAddTrue2(false);
        } catch (error) {
            console.error("Error creating beneficiary and spouse:", error);
            toast.error("An error occurred while processing the request");
        }
    };

    const handleCheckBox = (e) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked,
        });
    };
    function calculateAge(birthDate) {
        const today = new Date();
        const birthDateObj = new Date(birthDate.replace(/-/g, "/")); // Replace hyphens with slashes
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDiff = today.getMonth() - birthDateObj.getMonth();

        if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
        ) {
            age--;
        }

        return age;
    }

    const toProperCase = (str) => {
        return str.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };
    const handleDateChange = (name, newDate) => {
        setFormData((prevState) => ({
            ...prevState,
            [name]: dayjs(newDate),
        }));
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;

        let properCaseValue = value;

        if (
            [
                "firstName",
                "middleName",
                "lastName",
                "suffix",
                "spouseLastName",
                "spouseFirstName",
                "spouseMiddleName",
                "spouseSuffix",
                "birthDate",
                "secondSpouseLastName",
                "secondSpouseFirstName",
                "secondSpouseMiddleName",
                "secondSpouseSuffix",
                "previousSpouseLastName",
                "previousSpouseFirstName",
                "previousSpouseMiddleName",
                "previousSpouseSuffix",
                "seniorLastName",
                "seniorFirstName",
                "seniorMiddleName",
                "seniorSuffix",
                "relocationDate",
            ].includes(name)
        ) {
            properCaseValue = toProperCase(value);
        }

        // Update formData
        setFormData({ ...formData, [name]: properCaseValue });
        setFormData({ ...formData, [name]: properCaseValue });
        if (name === "batchNumber") {
            // Check if batchNumber is blank
            if (!properCaseValue.trim()) {
                // Set filtered data to an empty array if batchNumber is blank
                setFilteredBeneficiaries([]);
            } else {
                // Filter data based on the updated batchNumber
                const filteredData = data.filter(
                    (item) => item.batchNumber === properCaseValue
                );

                // Update the filtered data state
                setFilteredBeneficiaries(filteredData);
            }
        }
    };

    const [filteredBeneficiaryIds, setFilteredBeneficiaryIds] = useState([]);

    const [filteredBeneficiaries, setFilteredBeneficiaries] = useState([]);

    // Include dependencies in the array that are used in the fetchData function

    // Usage examples:

    // 1. If you want to filter by RequestNumber (isInRequestComponent is true)
    // Replace 'yourRequestNumber' with the actual RequestNumber you want to filter for

    // 2. If you don't want to filter by RequestNumber (isInRequestComponent is false)

    const handleEdit = (item) => {
        setIsEditMode(true);
        setFormData({
            id: item.id,
            lastName: item.lastName,
            firstName: item.firstName,
            middleName: item.middleName,
            suffix: item.suffix,
            hhpwd: item.hhpwd,
            solo: item.solo,
            sex: item.sex,
            birthDate: item.birthDate,
            civilStatus: item.civilStatus,
            tagNumber: item.tagNumber,
            tenurialStatus: item.tenurialStatus,
            pqNumber: item.pqNumber,
            spouseLastName: item.Spouse ? item.Spouse.spouseLastName : "",
            spouseFirstName: item.Spouse ? item.Spouse.spouseFirstName : "",
            spouseMiddleName: item.Spouse ? item.Spouse.spouseMiddleName : "",
            spouseSuffix: item.Spouse ? item.Spouse.spouseSuffix : "",
            spouseSex: item.Spouse ? item.Spouse.spouseSex : "",
            spouseAge: item.Spouse ? item.Spouse.spouseAge : "",
            spouseCivilStatus: item.Spouse ? item.Spouse.spouseCivilStatus : "",
            spousepwd: item.Spouse ? item.Spouse.spousepwd : "",
            secondSpouseLastName: item.SecondSpouse
                ? item.SecondSpouse.secondSpouseLastName
                : "",
            secondSpouseFirstName: item.SecondSpouse
                ? item.SecondSpouse.secondSpouseFirstName
                : "",
            secondSpouseMiddleName: item.SecondSpouse
                ? item.SecondSpouse.secondSpouseMiddleName
                : "",
            secondSpouseSuffix: item.SecondSpouse
                ? item.SecondSpouse.secondSpouseSuffix
                : "",
            secondSpouseSex: item.SecondSpouse
                ? item.SecondSpouse.secondSpouseSex
                : "",
            secondSpouseAge: item.SecondSpouse
                ? item.SecondSpouse.secondSpouseAge
                : "",
            secondSpouseCivilStatus: item.Spouse
                ? item.SecondSpouse.secondSpouseCivilStatus
                : "",
            previousSpouseLastName: item.PreviousSpouse
                ? item.PreviousSpouse.previousSpouseLastName
                : "",
            previousSpouseFirstName: item.PreviousSpouse
                ? item.PreviousSpouse.previousSpouseFirstName
                : "",
            previousSpouseMiddleName: item.PreviousSpouse
                ? item.PreviousSpouse.previousSpouseMiddleName
                : "",
            previousSpouseSuffix: item.PreviousSpouse
                ? item.PreviousSpouse.previousSpouseSuffix
                : "",
            previousSpouseSex: item.PreviousSpouse
                ? item.PreviousSpouse.previousSpouseSex
                : "",
            previousSpouseAge: item.PreviousSpouse
                ? item.PreviousSpouse.previousSpouseAge
                : "",
            previousSpouseCivilStatus: item.PreviousSpouse
                ? item.PreviousSpouse.previousSpouseCivilStatus
                : "",
            seniorLastName: item.Senior ? item.Senior.seniorLastName : "",
            seniorFirstName: item.Senior ? item.Senior.seniorFirstName : "",
            seniorMiddleName: item.Senior ? item.Senior.seniorMiddleName : "",
            seniorSuffix: item.Senior ? item.Senior.seniorSuffix : "",
            seniorSex: item.Senior ? item.Senior.seniorSex : "",
            seniorAge: item.Senior ? item.Senior.seniorAge : "",
            seniorCivilStatus: item.Senior ? item.Senior.seniorCivilStatus : "",
            seniorpwd: item.Senior ? item.Senior.seniorpwd : "",
            seniorsolo: item.Senior ? item.Senior.seniorsolo : "",
            batchNumber: item.batchNumber,
            relocationDate: item.relocationDate,
            remarks: item.remarks,
        });
    };
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAllCheckbox = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            // If select all is checked, set all IDs to selected
            const allIds = data.map((item) => item.id); // Use the entire dataset instead of currentItems
            setSelectedIds(allIds);
        } else {
            // If select all is unchecked, clear all selected IDs
            setSelectedIds([]);
        }
    };

    const handleRowCheckboxClick = (item) => {
        if (selectedIds.includes(item.id)) {
            setSelectedIds(selectedIds.filter((id) => id !== item.id));
        } else {
            // If the item is not selected, add it to the selectedIds
            setSelectedIds([...selectedIds, item.id]);
        }
    };

    // Separate useEffect for batchNumber filtering
    useEffect(() => {
        if (!isInRequestComponent && !formData.batchNumber?.trim()) {
            // Set filteredBeneficiaries to an empty array if batchNumber is blank
            setFilteredBeneficiaries([]);
        } else if (data.length > 0 && !isInRequestComponent) {
            // Filter data based on the batchNumber only when data is loaded
            const filteredData = data.filter(
                (item) => item.batchNumber === formData.batchNumber
            );
            setFilteredBeneficiaries(filteredData);
        }
    }, [formData.batchNumber, data]);

    const dataToSend = { selectedIds };
    useEffect(() => {
        onDataFromChild(dataToSend);
    }, [selectedIds]);
    if (!accessLevel) {
        return <div>Loading...</div>;
    }
    return (
        <>
            <div>
                {!isInRequestComponent ? (
                    <>
                        <Header />
                        <Sidebar />
                    </>
                ) : null}
                {(isInRequestComponent && accessLevel > 0.5) ||
                !isInRequestComponent ? (
                    <div className="content-wrapper pl-2 pr-2 pb-3">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h1>Beneficiary Information</h1>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="pl-2 pr-2">
                            <div className="container-fluid">
                                {isInRequestComponent === false &&
                                accessLevel !== 2 ? (
                                    <Accordion
                                        sx={AccordionStyle}
                                        className="elevation-2"
                                        style={{
                                            borderTop: "4px solid #292726",
                                            borderRadius: "15px",
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <div className="card-title font-weight-bold p-2">
                                                <BsIcons.BsPlusLg className="mb-1" />{" "}
                                                Information on Households
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="p-2">
                                                <hr
                                                    className="mt-0 pt-0"
                                                    style={{}}
                                                />
                                                <small className="text-muted">
                                                    <BsIcons.BsExclamationCircleFill className="mb-1" />{" "}
                                                    Check all the required
                                                    fields before saving
                                                </small>

                                                <div>
                                                    {" "}
                                                    {/* NAME OF HOUSEHOLD HEAD */}
                                                    <p className="font-weight-bold pt-2">
                                                        NAME OF HOUSEHOLD HEAD
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Last Name"
                                                                name="lastName"
                                                                value={
                                                                    formData.lastName
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                variant="outlined"
                                                                size="small"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    style: labelStyle,
                                                                }}
                                                                type="text"
                                                            />
                                                            {errors.lastName && (
                                                                <span className="error">
                                                                    {
                                                                        errors.lastName
                                                                    }
                                                                </span>
                                                            )}
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="First Name"
                                                                name="firstName"
                                                                value={
                                                                    formData.firstName
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                variant="outlined"
                                                                size="small"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    style: labelStyle,
                                                                }}
                                                                type="text"
                                                            />
                                                            {errors.firstName && (
                                                                <span className="error">
                                                                    {
                                                                        errors.firstName
                                                                    }
                                                                </span>
                                                            )}
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Middle Name"
                                                                name="middleName"
                                                                value={
                                                                    formData.middleName
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                variant="outlined"
                                                                size="small"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    style: labelStyle,
                                                                }}
                                                                type="text"
                                                            />
                                                            {errors.middleName && (
                                                                <span className="error">
                                                                    {
                                                                        errors.middleName
                                                                    }
                                                                </span>
                                                            )}
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <FormControl
                                                                size="small"
                                                                fullWidth
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    style={
                                                                        selectLabelStyle
                                                                    }
                                                                >
                                                                    Suffix
                                                                </InputLabel>
                                                                <Select
                                                                    labelId=""
                                                                    id="suffix"
                                                                    label="Suffix"
                                                                    name="suffix"
                                                                    value={
                                                                        formData.suffix
                                                                    }
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                >
                                                                    <MenuItem value="Jr.">
                                                                        Jr.
                                                                    </MenuItem>
                                                                    <MenuItem value="Sr.">
                                                                        Sr.
                                                                    </MenuItem>
                                                                    <MenuItem value="I">
                                                                        I
                                                                    </MenuItem>
                                                                    <MenuItem value="II">
                                                                        II
                                                                    </MenuItem>
                                                                    <MenuItem value="III">
                                                                        III
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-sm-3 pt-2">
                                                            <FormControl
                                                                size="small"
                                                                fullWidth
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    style={
                                                                        selectLabelStyle
                                                                    }
                                                                >
                                                                    Sex
                                                                </InputLabel>
                                                                <Select
                                                                    labelId=""
                                                                    id=""
                                                                    name="sex"
                                                                    value={
                                                                        formData.sex
                                                                    }
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                    defaultValue={
                                                                        ""
                                                                    }
                                                                    label="Sex"
                                                                >
                                                                    <MenuItem value="Male">
                                                                        Male
                                                                    </MenuItem>
                                                                    <MenuItem value="Female">
                                                                        Female
                                                                    </MenuItem>
                                                                </Select>
                                                                {errors.sex && (
                                                                    <span className="error">
                                                                        {
                                                                            errors.sex
                                                                        }
                                                                    </span>
                                                                )}
                                                            </FormControl>
                                                        </div>

                                                        <div
                                                            className="col-sm-3"
                                                            style={{
                                                                paddingTop:
                                                                    "0%",
                                                                top: "0%",
                                                            }}
                                                        >
                                                            <LocalizationProvider
                                                                dateAdapter={
                                                                    AdapterDayjs
                                                                }
                                                                style={{
                                                                    paddingTop:
                                                                        "0",
                                                                    marginTop:
                                                                        "0",
                                                                }}
                                                            >
                                                                <DemoContainer
                                                                    components={[
                                                                        "DatePicker",
                                                                    ]}
                                                                    slotProps={{
                                                                        textField:
                                                                            {
                                                                                marginTop:
                                                                                    "0px",
                                                                            },
                                                                    }}
                                                                >
                                                                    <DesktopDatePicker
                                                                        label="Birthdate"
                                                                        name="birthDate"
                                                                        value={
                                                                            formData.birthDate
                                                                        }
                                                                        onChange={(
                                                                            newDate
                                                                        ) =>
                                                                            handleDateChange(
                                                                                "birthDate",
                                                                                newDate
                                                                            )
                                                                        }
                                                                        className="w-100"
                                                                        slotProps={{
                                                                            textField:
                                                                                {
                                                                                    size: "small",
                                                                                    marginTop:
                                                                                        "0px",
                                                                                    fontWeight:
                                                                                        "normal",
                                                                                },
                                                                        }}
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </div>

                                                        <div className="col-sm-3 pt-2">
                                                            <FormControl
                                                                size="small"
                                                                fullWidth
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    style={
                                                                        selectLabelStyle
                                                                    }
                                                                >
                                                                    Civil Status
                                                                </InputLabel>
                                                                <Select
                                                                    labelId=""
                                                                    id=""
                                                                    name="civilStatus"
                                                                    label="Civil Status"
                                                                    defaultValue={
                                                                        ""
                                                                    }
                                                                    value={
                                                                        formData.civilStatus
                                                                    }
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                >
                                                                    <MenuItem value="Single">
                                                                        Single
                                                                    </MenuItem>
                                                                    <MenuItem value="Married">
                                                                        Married
                                                                    </MenuItem>
                                                                    <MenuItem value="Widow/er">
                                                                        Widow/er
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-sm-3 pt-2">
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Tag Number"
                                                                variant="outlined"
                                                                name="tagNumber"
                                                                value={
                                                                    formData.tagNumber
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                size="small"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    style: labelStyle,
                                                                }}
                                                                type="text"
                                                            />
                                                            {errors.tagNumber && (
                                                                <span className="error">
                                                                    {
                                                                        errors.tagNumber
                                                                    }
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-sm-3 pt-2">
                                                            <FormControl
                                                                size="small"
                                                                fullWidth
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    style={
                                                                        selectLabelStyle
                                                                    }
                                                                >
                                                                    Tenurial
                                                                    Status
                                                                </InputLabel>
                                                                <Select
                                                                    labelId=""
                                                                    id=""
                                                                    label="TenurialStatus"
                                                                    name="tenurialStatus"
                                                                    value={
                                                                        formData.tenurialStatus
                                                                    }
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                    defaultValue={
                                                                        ""
                                                                    }
                                                                >
                                                                    <MenuItem value="Structure Owner">
                                                                        Structure
                                                                        owner
                                                                    </MenuItem>
                                                                    <MenuItem value="Co-Owner">
                                                                        Co-Owner
                                                                    </MenuItem>
                                                                    <MenuItem value="Renter">
                                                                        Renter
                                                                    </MenuItem>
                                                                    <MenuItem value="Caretaker">
                                                                        Caretaker
                                                                    </MenuItem>
                                                                    <MenuItem value="Sharer/Rent Free Occupant">
                                                                        Sharer/Rent
                                                                        Free
                                                                        Occupant
                                                                    </MenuItem>
                                                                    <MenuItem value="Mortgagee">
                                                                        Mortgagee
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                            {errors.tenurialStatus && (
                                                                <span className="error">
                                                                    {
                                                                        errors.tenurialStatus
                                                                    }
                                                                </span>
                                                            )}
                                                        </div>

                                                        <div className="col-sm-3 pt-2">
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="PQ Number"
                                                                variant="outlined"
                                                                size="small"
                                                                fullWidth
                                                                name="pqNumber"
                                                                value={
                                                                    formData.pqNumber
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                InputLabelProps={{
                                                                    style: labelStyle,
                                                                }}
                                                                type="text"
                                                            />
                                                            {errors.pqNumber && (
                                                                <span className="error">
                                                                    {
                                                                        errors.pqNumber
                                                                    }
                                                                </span>
                                                            )}
                                                        </div>

                                                        <div
                                                            className="col-sm-3"
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <div className="mt-2">
                                                                <Checkbox
                                                                    {...label}
                                                                />
                                                                <label>
                                                                    PWD
                                                                </label>
                                                            </div>

                                                            <div className="mt-2 ml-3">
                                                                <Checkbox
                                                                    {...label}
                                                                />
                                                                <label>
                                                                    Solo Parent
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    {" "}
                                                    {/* NAME OF SPOUSE/COMMON LAW PARTNER */}
                                                    <p className="font-weight-bold mt-5">
                                                        NAME OF SPOUSE/COMMON
                                                        LAW PARTNER
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Last Name"
                                                                variant="outlined"
                                                                size="small"
                                                                fullWidth
                                                                name="spouseLastName"
                                                                value={
                                                                    formData.spouseLastName
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                InputLabelProps={{
                                                                    style: labelStyle,
                                                                }}
                                                                type="text"
                                                            />
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="First Name"
                                                                variant="outlined"
                                                                size="small"
                                                                fullWidth
                                                                name="spouseFirstName"
                                                                value={
                                                                    formData.spouseFirstName
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                InputLabelProps={{
                                                                    style: labelStyle,
                                                                }}
                                                                type="text"
                                                            />
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Middle Name"
                                                                variant="outlined"
                                                                size="small"
                                                                fullWidth
                                                                name="spouseMiddleName"
                                                                value={
                                                                    formData.spouseMiddleName
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                InputLabelProps={{
                                                                    style: labelStyle,
                                                                }}
                                                                type="text"
                                                            />
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <FormControl
                                                                size="small"
                                                                fullWidth
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    style={
                                                                        selectLabelStyle
                                                                    }
                                                                >
                                                                    Suffix
                                                                </InputLabel>
                                                                <Select
                                                                    labelId=""
                                                                    id="spouseSuffix"
                                                                    name="spouseSuffix"
                                                                    value={
                                                                        formData.spouseSuffix
                                                                    }
                                                                    label="Suffix"
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                >
                                                                    <MenuItem value="Jr.">
                                                                        Jr.
                                                                    </MenuItem>
                                                                    <MenuItem value="Sr.">
                                                                        Sr.
                                                                    </MenuItem>
                                                                    <MenuItem value="I">
                                                                        I
                                                                    </MenuItem>
                                                                    <MenuItem value="II">
                                                                        II
                                                                    </MenuItem>
                                                                    <MenuItem value="III">
                                                                        III
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-sm-3 pt-2">
                                                            <FormControl
                                                                size="small"
                                                                fullWidth
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    style={
                                                                        selectLabelStyle
                                                                    }
                                                                >
                                                                    Sex
                                                                </InputLabel>
                                                                <Select
                                                                    labelId=""
                                                                    id=""
                                                                    name="spouseSex"
                                                                    value={
                                                                        formData.spouseSex
                                                                    }
                                                                    label="Sex"
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                >
                                                                    <MenuItem value="Male">
                                                                        Male
                                                                    </MenuItem>
                                                                    <MenuItem value="Female">
                                                                        Female
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <div
                                                            className="col-sm-3"
                                                            style={{
                                                                paddingTop:
                                                                    "0%",
                                                                top: "0%",
                                                            }}
                                                        >
                                                            <LocalizationProvider
                                                                dateAdapter={
                                                                    AdapterDayjs
                                                                }
                                                                style={{
                                                                    paddingTop:
                                                                        "0",
                                                                    marginTop:
                                                                        "0",
                                                                }}
                                                            >
                                                                <DemoContainer
                                                                    components={[
                                                                        "DatePicker",
                                                                    ]}
                                                                    slotProps={{
                                                                        textField:
                                                                            {
                                                                                marginTop:
                                                                                    "0px",
                                                                            },
                                                                    }}
                                                                >
                                                                    <DesktopDatePicker
                                                                        label="Birthdate"
                                                                        className="w-100"
                                                                        name="spouseAge"
                                                                        value={
                                                                            formData.spouseAge
                                                                        }
                                                                        onChange={(
                                                                            newDate
                                                                        ) =>
                                                                            handleDateChange(
                                                                                "spouseAge",
                                                                                newDate
                                                                            )
                                                                        }
                                                                        slotProps={{
                                                                            textField:
                                                                                {
                                                                                    size: "small",
                                                                                    marginTop:
                                                                                        "0px",
                                                                                    fontWeight:
                                                                                        "normal",
                                                                                },
                                                                        }}
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </div>

                                                        <div className="col-sm-3 pt-2">
                                                            <FormControl
                                                                size="small"
                                                                fullWidth
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    style={
                                                                        selectLabelStyle
                                                                    }
                                                                >
                                                                    Civil Status
                                                                </InputLabel>
                                                                <Select
                                                                    labelId=""
                                                                    id=""
                                                                    name="spouseCivilStatus"
                                                                    value={
                                                                        formData.spouseCivilStatus
                                                                    }
                                                                    label="CivilStatus"
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                >
                                                                    <MenuItem value="Single">
                                                                        Single
                                                                    </MenuItem>
                                                                    <MenuItem value="Married">
                                                                        Married
                                                                    </MenuItem>
                                                                    <MenuItem value="Widow/er">
                                                                        Widow/er
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <div className="mt-2">
                                                                <Checkbox
                                                                    {...label}
                                                                />
                                                                <label>
                                                                    PWD
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    {" "}
                                                    {/* NAME OF SENIOR CO-OWNER */}
                                                    <p className="font-weight-bold mt-5">
                                                        NAME OF SENIOR CO-OWNER
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Last Name"
                                                                variant="outlined"
                                                                size="small"
                                                                name="seniorLastName"
                                                                value={
                                                                    formData.seniorLastName
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    style: labelStyle,
                                                                }}
                                                                type="text"
                                                            />
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="First Name"
                                                                variant="outlined"
                                                                size="small"
                                                                name="seniorFirstName"
                                                                value={
                                                                    formData.seniorFirstName
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    style: labelStyle,
                                                                }}
                                                                type="text"
                                                            />
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Middle Name"
                                                                variant="outlined"
                                                                size="small"
                                                                name="seniorMiddleName"
                                                                value={
                                                                    formData.seniorMiddleName
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    style: labelStyle,
                                                                }}
                                                                type="text"
                                                            />
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <FormControl
                                                                size="small"
                                                                fullWidth
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    style={
                                                                        selectLabelStyle
                                                                    }
                                                                >
                                                                    Suffix
                                                                </InputLabel>
                                                                <Select
                                                                    labelId=""
                                                                    id="seniorSuffix"
                                                                    name="seniorSuffix"
                                                                    value={
                                                                        formData.seniorSuffix
                                                                    }
                                                                    label="Suffix"
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                >
                                                                    <MenuItem value="Jr.">
                                                                        Jr.
                                                                    </MenuItem>
                                                                    <MenuItem value="Sr.">
                                                                        Sr.
                                                                    </MenuItem>
                                                                    <MenuItem value="I">
                                                                        I
                                                                    </MenuItem>
                                                                    <MenuItem value="II">
                                                                        II
                                                                    </MenuItem>
                                                                    <MenuItem value="III">
                                                                        III
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-sm-3 pt-2">
                                                            <FormControl
                                                                size="small"
                                                                fullWidth
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    style={
                                                                        selectLabelStyle
                                                                    }
                                                                >
                                                                    Sex
                                                                </InputLabel>
                                                                <Select
                                                                    labelId=""
                                                                    id=""
                                                                    name="seniorSex"
                                                                    value={
                                                                        formData.seniorSex
                                                                    }
                                                                    label="Sex"
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                >
                                                                    <MenuItem value="Male">
                                                                        Male
                                                                    </MenuItem>
                                                                    <MenuItem value="Female">
                                                                        Female
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <div
                                                            className="col-sm-3"
                                                            style={{
                                                                paddingTop:
                                                                    "0%",
                                                                top: "0%",
                                                            }}
                                                        >
                                                            <LocalizationProvider
                                                                dateAdapter={
                                                                    AdapterDayjs
                                                                }
                                                                style={{
                                                                    paddingTop:
                                                                        "0",
                                                                    marginTop:
                                                                        "0",
                                                                }}
                                                            >
                                                                <DemoContainer
                                                                    components={[
                                                                        "DatePicker",
                                                                    ]}
                                                                    slotProps={{
                                                                        textField:
                                                                            {
                                                                                marginTop:
                                                                                    "0px",
                                                                            },
                                                                    }}
                                                                >
                                                                    <DesktopDatePicker
                                                                        label="Birthdate"
                                                                        className="w-100"
                                                                        name="seniorAge"
                                                                        value={
                                                                            formData.seniorAge
                                                                        }
                                                                        onChange={(
                                                                            newDate
                                                                        ) =>
                                                                            handleDateChange(
                                                                                "seniorAge",
                                                                                newDate
                                                                            )
                                                                        }
                                                                        slotProps={{
                                                                            textField:
                                                                                {
                                                                                    size: "small",
                                                                                    marginTop:
                                                                                        "0px",
                                                                                    fontWeight:
                                                                                        "normal",
                                                                                },
                                                                        }}
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </div>

                                                        <div className="col-sm-3 pt-2">
                                                            <FormControl
                                                                size="small"
                                                                fullWidth
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    style={
                                                                        selectLabelStyle
                                                                    }
                                                                >
                                                                    Civil Status
                                                                </InputLabel>
                                                                <Select
                                                                    labelId=""
                                                                    id=""
                                                                    name="seniorCivilStatus"
                                                                    value={
                                                                        formData.seniorCivilStatus
                                                                    }
                                                                    label="CivilStatus"
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                >
                                                                    <MenuItem value="Single">
                                                                        Single
                                                                    </MenuItem>
                                                                    <MenuItem value="Married">
                                                                        Married
                                                                    </MenuItem>
                                                                    <MenuItem value="Widow/er">
                                                                        Widow/er
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <div
                                                            className="col-sm-3"
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <div className="mt-2">
                                                                <Checkbox
                                                                    {...label}
                                                                />
                                                                <label>
                                                                    PWD
                                                                </label>
                                                            </div>

                                                            <div className="mt-2 ml-3">
                                                                <Checkbox
                                                                    {...label}
                                                                />
                                                                <label>
                                                                    Solo Parent
                                                                </label>
                                                            </div>
                                                        </div>

                                                        {/* submit button */}
                                                        {/* <div className="col-sm-12 mt-5">
                                                <Button
                                                    variant="contained"
                                                    className="p-2 pl-5 pr-5 float-right text-dark"
                                                    onClick={handleSubmit}
                                                    style={{
                                                        backgroundColor:
                                                            "#2be0a9",
                                                        borderRadius: "50px",
                                                    }}
                                                >
                                                    Submit{" "}
                                                </Button>
                                            </div> */}
                                                    </div>
                                                </div>
                                                <div>
                                                    {" "}
                                                    {/* NAME OF SENIOR CO-OWNER */}
                                                    <p className="font-weight-bold mt-5">
                                                        SCHEDULE OF RELOCATION
                                                        AND RESETTLEMENT (RR)
                                                    </p>
                                                    <div className="row">
                                                        <div
                                                            className="col-sm-6"
                                                            style={{
                                                                paddingTop:
                                                                    "8px",
                                                            }}
                                                        >
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="Batch Number"
                                                                variant="outlined"
                                                                name="batchNumber"
                                                                value={
                                                                    formData.batchNumber
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                size="small"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    style: labelStyle,
                                                                }}
                                                                type="text"
                                                            />
                                                            {errors.batchNumber && (
                                                                <span className="error">
                                                                    {
                                                                        errors.batchNumber
                                                                    }
                                                                </span>
                                                            )}
                                                        </div>

                                                        <div
                                                            className="col-sm-6"
                                                            style={{
                                                                paddingTop:
                                                                    "0%",
                                                                top: "0%",
                                                            }}
                                                        >
                                                            <LocalizationProvider
                                                                dateAdapter={
                                                                    AdapterDayjs
                                                                }
                                                                style={{
                                                                    paddingTop:
                                                                        "0",
                                                                    marginTop:
                                                                        "0",
                                                                }}
                                                            >
                                                                <DemoContainer
                                                                    components={[
                                                                        "DatePicker",
                                                                    ]}
                                                                    slotProps={{
                                                                        textField:
                                                                            {
                                                                                marginTop:
                                                                                    "0px",
                                                                            },
                                                                    }}
                                                                >
                                                                    <DesktopDatePicker
                                                                        label="Relocation Date"
                                                                        className="w-100"
                                                                        name="relocationDate"
                                                                        value={
                                                                            formData.relocationDate
                                                                        }
                                                                        onChange={(
                                                                            newDate
                                                                        ) =>
                                                                            handleDateChange(
                                                                                "relocationDate",
                                                                                newDate
                                                                            )
                                                                        }
                                                                        slotProps={{
                                                                            textField:
                                                                                {
                                                                                    size: "small",

                                                                                    marginTop:
                                                                                        "0px",
                                                                                    fontWeight:
                                                                                        "normal",
                                                                                },
                                                                        }}
                                                                    />
                                                                    {/* {errors.relocationDate && (
                                                                    <span className="error">
                                                                        {
                                                                            errors.relocationDate
                                                                        }
                                                                    </span>
                                                                )} */}
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {/* submit button */}
                                                        <div className="col-sm-12 mt-5">
                                                            <Button
                                                                variant="contained"
                                                                className="p-2 pl-5 pr-5 float-right text-dark"
                                                                onClick={
                                                                    handleSubmit
                                                                }
                                                                style={{
                                                                    backgroundColor:
                                                                        "#2be0a9",
                                                                    borderRadius:
                                                                        "50px",
                                                                }}
                                                            >
                                                                Submit{" "}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ) : null}
                                <div className="mt-4">
                                    <div
                                        className="card elevation-2"
                                        style={{
                                            borderTop: "4px solid #292726",
                                            borderRadius: "15px",
                                        }}
                                    >
                                        {currentStep === 1 && (
                                            <div className="card-header">
                                                <h3 className="card-title font-weight-bold">
                                                    List of Household
                                                </h3>
                                            </div>
                                        )}

                                        {currentStep === 2 && (
                                            <div className="card-header">
                                                <h3 className="card-title font-weight-bold">
                                                    List of Batches
                                                </h3>
                                            </div>
                                        )}

                                        <div
                                            className="card-body table-responsive p-0"
                                            style={{ height: 400 }}
                                        >
                                            {currentStep === 1 && (
                                                <table className="table table-head-fixed table-hover table-bordered text-wrap">
                                                    <thead
                                                        style={{
                                                            borderBottom:
                                                                "2px solid #ccc",
                                                        }}
                                                    >
                                                        <tr>
                                                            {accessLevel > 2 ||
                                                            accessLevel <= 1 ? (
                                                                <th
                                                                    rowspan="2"
                                                                    colspan="1"
                                                                    style={{
                                                                        border: "1px solid #ccc",
                                                                        verticalAlign:
                                                                            "middle",
                                                                        backgroundColor:
                                                                            "#f4f4f4",
                                                                    }}
                                                                >
                                                                    {isInRequestComponent &&
                                                                    accessLevel <
                                                                        2 &&
                                                                    accessLevel >
                                                                        0.5 ? (
                                                                        <input
                                                                            type="checkbox"
                                                                            onChange={
                                                                                handleSelectAllCheckbox
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                </th>
                                                            ) : null}
                                                            <th
                                                                rowspan="2"
                                                                style={{
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor:
                                                                        "#f4f4f4",
                                                                    verticalAlign:
                                                                        "middle",
                                                                }}
                                                            >
                                                                #
                                                            </th>
                                                            <th
                                                                colspan="5"
                                                                className="text-center"
                                                                style={{
                                                                    verticalAlign:
                                                                        "middle",
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor:
                                                                        "#f4f4f4",
                                                                }}
                                                            >
                                                                NAME OF
                                                                HOUSEHOLD
                                                            </th>
                                                            <th
                                                                rowspan="2"
                                                                className="text-center"
                                                                style={{
                                                                    verticalAlign:
                                                                        "middle",
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor:
                                                                        "#f4f4f4",
                                                                }}
                                                            >
                                                                Age
                                                            </th>
                                                            <th
                                                                rowspan="2"
                                                                style={{
                                                                    verticalAlign:
                                                                        "middle",
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor:
                                                                        "#f4f4f4",
                                                                }}
                                                            >
                                                                Civil Status
                                                            </th>
                                                            <th
                                                                rowspan="2"
                                                                style={{
                                                                    verticalAlign:
                                                                        "middle",
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor:
                                                                        "#f4f4f4",
                                                                }}
                                                            >
                                                                Tag Number
                                                            </th>
                                                            <th
                                                                rowspan="2"
                                                                style={{
                                                                    verticalAlign:
                                                                        "middle",
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor:
                                                                        "#f4f4f4",
                                                                }}
                                                            >
                                                                Tenurial
                                                            </th>
                                                            <th
                                                                rowspan="2"
                                                                style={{
                                                                    verticalAlign:
                                                                        "middle",
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor:
                                                                        "#f4f4f4",
                                                                }}
                                                            >
                                                                PQ Number
                                                            </th>
                                                            <th
                                                                rowspan="2"
                                                                className="text-center"
                                                                style={{
                                                                    verticalAlign:
                                                                        "middle",
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor:
                                                                        "#f4f4f4",
                                                                }}
                                                            >
                                                                Action
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <th
                                                                rowspan="2"
                                                                style={{
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor:
                                                                        "#f4f4f4",
                                                                }}
                                                            >
                                                                Last Name
                                                            </th>
                                                            <th
                                                                rowspan="2"
                                                                style={{
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor:
                                                                        "#f4f4f4",
                                                                }}
                                                            >
                                                                First Name
                                                            </th>
                                                            <th
                                                                rowspan="2"
                                                                style={{
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor:
                                                                        "#f4f4f4",
                                                                }}
                                                            >
                                                                Middle Name
                                                            </th>
                                                            <th
                                                                rowspan="2"
                                                                style={{
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor:
                                                                        "#f4f4f4",
                                                                }}
                                                            >
                                                                Suffix
                                                            </th>
                                                            <th
                                                                rowspan="2"
                                                                style={{
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor:
                                                                        "#f4f4f4",
                                                                }}
                                                            >
                                                                Sex
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {filteredBeneficiaries.map(
                                                            (item, index) => {
                                                                const age =
                                                                    calculateAge(
                                                                        item.birthDate
                                                                    ); // Calculate age for each record
                                                                const continuousIndex =
                                                                    (currentPage -
                                                                        1) *
                                                                        itemsPerPage +
                                                                    index +
                                                                    1;

                                                                return (
                                                                    <tr
                                                                        key={
                                                                            item.id
                                                                        }
                                                                    >
                                                                        {isInRequestComponent &&
                                                                        accessLevel ==
                                                                            1 ? (
                                                                            <td className="text-center borderRight">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    style={{
                                                                                        width: "12px",
                                                                                    }}
                                                                                    checked={selectedIds.includes(
                                                                                        item.id
                                                                                    )}
                                                                                    onChange={() =>
                                                                                        handleRowCheckboxClick(
                                                                                            item
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        ) : null}
                                                                        <td className="text-middle">
                                                                            {
                                                                                continuousIndex
                                                                            }
                                                                        </td>
                                                                        <td className="text-middle">
                                                                            {
                                                                                item.lastName
                                                                            }
                                                                        </td>
                                                                        <td className="text-middle">
                                                                            {
                                                                                item.firstName
                                                                            }
                                                                        </td>
                                                                        <td className="text-middle">
                                                                            {
                                                                                item.middleName
                                                                            }
                                                                        </td>
                                                                        <td className="text-middle">
                                                                            {
                                                                                item.suffix
                                                                            }
                                                                        </td>
                                                                        <td className="text-middle">
                                                                            {
                                                                                item.sex
                                                                            }
                                                                        </td>
                                                                        <td className="text-middle text-center">
                                                                            {
                                                                                age
                                                                            }
                                                                        </td>
                                                                        <td className="text-middle">
                                                                            {
                                                                                item.civilStatus
                                                                            }
                                                                        </td>
                                                                        <td className="text-middle">
                                                                            {
                                                                                item.tagNumber
                                                                            }
                                                                        </td>
                                                                        <td className="text-middle">
                                                                            {
                                                                                item.tenurialStatus
                                                                            }
                                                                        </td>
                                                                        <td className="text-middle">
                                                                            {
                                                                                item.pqNumber
                                                                            }
                                                                        </td>
                                                                        <td className="text-middle text-center p-0">
                                                                            <Tooltip title="View Info">
                                                                                <IconButton>
                                                                                    <a
                                                                                        href={`/#/beneficiary-info/${item.id}`}
                                                                                    >
                                                                                        <RemoveRedEyeRoundedIcon
                                                                                            className="text-lg"
                                                                                            style={{
                                                                                                color: "#34a8ff",
                                                                                            }}
                                                                                        />
                                                                                    </a>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            {/* 
                                                                            <Tooltip title="Edit Info">
                                                                                <IconButton>
                                                                                    <a href="beneficiary-info">
                                                                                        <BorderColorIcon
                                                                                            className="text-lg"
                                                                                            style={{
                                                                                                color: "#2be0a9",
                                                                                            }}
                                                                                        />
                                                                                    </a>
                                                                                </IconButton>
                                                                            </Tooltip> */}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                    </tbody>
                                                </table>
                                            )}

                                            {currentStep === 2 && (
                                                <div>
                                                    <table className="table table-head-fixed table-hover table-bordered text-wrap">
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        width: "150px",
                                                                    }}
                                                                >
                                                                    Sequence No.
                                                                </th>
                                                                <th>
                                                                    Batch Number
                                                                </th>
                                                                <th>
                                                                    Resettlement
                                                                    Site
                                                                </th>
                                                                <th>
                                                                    Project Name
                                                                </th>
                                                                <th className="text-center">
                                                                    Count
                                                                </th>
                                                                <th className="text-center">
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td className="text-center">
                                                                    1
                                                                </td>
                                                                <td>
                                                                    Trinidad
                                                                </td>
                                                                <td>
                                                                    Renz Llyod
                                                                </td>
                                                                <td>Bulawit</td>
                                                                <td className="text-center">
                                                                    -
                                                                </td>
                                                                <td className="text-center p-0">
                                                                    <Tooltip title="View Info">
                                                                        <IconButton>
                                                                            <a
                                                                                href={
                                                                                    "#"
                                                                                }
                                                                            >
                                                                                <RemoveRedEyeRoundedIcon
                                                                                    className="text-lg"
                                                                                    style={{
                                                                                        color: "#34a8ff",
                                                                                    }}
                                                                                />
                                                                            </a>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </div>

                                        <div
                                            className="card-footer"
                                            style={{
                                                borderBottomLeftRadius: "15px",
                                                borderBottomRightRadius: "15px",
                                            }}
                                        >
                                            <div className="row cardFooterCenter">
                                                <div className="col-sm-6">
                                                    {/* <label>1</label>{" "}
                                                    <span className="text-muted">
                                                        of
                                                    </span>{" "}
                                                    <label>3</label> &nbsp;
                                                    <IconButton>
                                                        <ArrowBackIosIcon className="text-md pl-1" />
                                                    </IconButton>
                                                    <IconButton>
                                                        <ArrowForwardIosRoundedIcon className="text-md pl-1" />
                                                    </IconButton> */}
                                                </div>

                                                <div className="col-sm-6">
                                                    {!isInRequestComponent ? (
                                                        <div>
                                                            <Link to="/batch">
                                                                <Button
                                                                    variant="contained"
                                                                    className="p-2 pl-5 pr-5 float-right text-dark cardFooterItem"
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#2be0a9",
                                                                        borderRadius:
                                                                            "50px",
                                                                    }}
                                                                    // onClick={() =>
                                                                    //     setCurrentStep(
                                                                    //         currentStep + 1
                                                                    //     )
                                                                    // }
                                                                >
                                                                    next &nbsp;{" "}
                                                                    <BsIcons.BsArrowRight />
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    ) : null}
                                                    {/* 
                                                {currentStep === 2 && (
                                                    <div>
                                                        <Button
                                                            variant="contained"
                                                            className="w-25 float-right text-dark cardFooterItem"
                                                            style={{
                                                                backgroundColor:
                                                                    "#2be0a9",
                                                                borderRadius:
                                                                    "50px",
                                                            }}
                                                            onClick={() =>
                                                                setCurrentStep(
                                                                    currentStep +
                                                                        1
                                                                )
                                                            }
                                                        >
                                                            next &nbsp;{" "}
                                                            <BsIcons.BsArrowRight />
                                                        </Button>

                                                        <Button
                                                            variant="outlined"
                                                            className="w-25 mr-3 float-right text-dark cardFooterItem"
                                                            style={{
                                                                border: "2px solid #2be0a9",
                                                                color: "#24dc8f",
                                                                borderRadius:
                                                                    "50px",
                                                            }}
                                                            onClick={() =>
                                                                setCurrentStep(
                                                                    currentStep -
                                                                        1
                                                                )
                                                            }
                                                        >
                                                            <BsIcons.BsArrowLeft />{" "}
                                                            &nbsp; previous
                                                        </Button>
                                                    </div>
                                                )} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default Beneficiary;
