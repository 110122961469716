// AccessDenied.jsx
import React from "react";

const AccessDenied = () => {
    return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
            <h1>Access Denied</h1>
            <p>You do not have permission to view this page.</p>
        </div>
    );
};

export default AccessDenied;
